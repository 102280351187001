<template>
  <v-container>
    <v-card>
      <v-card-title class="accent text-h5 font-weight-bold pa-3">
        <v-icon color="primary" left>mdi-playlist-check</v-icon>
        Serviços Prestados
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <ListaPrestados />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "ServicosPrestados",
  data() {
    return {};
  },
  components: {
    ListaPrestados: () => import("./components/ListaPrestados.vue"),
  },
};
</script>

<style lang="scss"></style>
